#archive-wrapper, #search-wrapper{
  margin-bottom:104px;
  header.page-header{
    background-color: $primary;
    padding:150px 0 28px 0 !important;
    color:$primary-light;
    margin-bottom:20px;
    .tit-archive{
      border-left:1px solid $primary-light;
      padding-left:29px;
      h1{
        font-size:$font-lg-5;
      }
      .ancestor{
        font-weight: $font-w-bold;
        text-transform: uppercase;
        @extend .pb-2;
        span{
          color: $second-color;
        }
      }
    }
  }
  article.row{
    padding-bottom:24px;
    h2.entry-title{
      font-size:$font-lg;
      font-weight: $font-w-medium;
    }
    time{
      color:$second-color;
      font-size: $font-small;
      display: block;
    }
    .category-search{

    }
    .post-type{
      @extend .mb-2;
      span{
        background-color: $primary;
        color: $primary-light;
        display: inline-block;
        font-size: $font-small;
        text-transform: uppercase;
        @extend .px-2;
      }
    }
  }
  #archive_menu_content{
    margin:115px 0  86px 0;
    font-weight:$font-w-semibold;
    font-size:$font-small;
    border-bottom:2px solid $primary-light81;
    ul{
      li{
        margin-right:80px;
        &.active{
          margin-bottom:-2px;
          border-bottom:2px solid $second-color;
          a{
            color:$second-color; 
          }
        }
      }
    }
  }
}
.post-type-archive{
  #archive-wrapper{ 
    .site-main{ 
      header{
        &.page-header{
          background-color: transparent;
          color:$primary;
          .tit-archive{
            border-left:0px;
          }
        }
      }
    }
  }
}
#search-wrapper{
  article.row{
    border-bottom: 1px solid $primary-light81;
    padding-bottom:0px;
    margin-bottom:24px;
    .image{
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
      @extend .pl-md-0;
      @extend .pl-3;
    }
    figure{
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .align-items-center;
      @extend .mb-3;
      @extend .mb-md-0;
    }
    .entry-header{
      padding-bottom: 20px;
      time{
        display: inline;
      }
      h2.entry-title{
        @extend .mt-2;
        @extend .mb-0;
      }
    }
  }
}