// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
@mixin section {
  @include media-breakpoint-up(md) {
    padding-right:$padding-section-right;
    padding-left:$padding-section-left;
  }
  @include media-breakpoint-down(md) {
    padding-right:$padding-section-right-sm;
    padding-left:$padding-section-left-sm;
  }
}

@mixin dark-box {
    background: $primary;
    color: $primary-light;
    a{
      color: $primary-light;
      &:hover{
        text-decoration: none;
      }
    }
    .card{
      border-radius: 0px;
      border:0px;
      &.bg-dark{
        background-color: lighten($primary, 5.10) !important;
      }
    }
  }

  @mixin inner-content{
    //@extend .py-5;
    padding-top: 60px;
    padding-bottom: 120px;
    //font-size: 1.125em;
    //padding-left: 6rem!important;
    //padding-right: 6rem!important;
    @include media-breakpoint-down(md) {
      padding-left: 2rem!important;
      padding-right: 2rem!important;
    }
    .site-main{
      @extend .flex-grow-1;
      .entry-header{
        @extend .pb-4;
        time{
          color: $second-color;
          font-weight: 600;
        }
        address{
          font-weight: 600;
        }
        .ico-cat{
          @extend .mt-4;
        }
        .entry-title{
          font-size: 2.375em;
          font-weight: 700;
          @include media-breakpoint-down(md) {
            font-size: 1.875em;
          }
        }
      }
      .entry-content{
        position: relative;
        .utility-row{
          position: absolute;
          width: 4rem;
          left: -5rem;
          @extend .d-flex;
          @extend .flex-row;
          @extend .flex-md-column;
          @extend .justify-content-center;
          @extend .justify-content-md-start;
          @extend .mb-1;
          @extend .mb-lg-4;
          @include media-breakpoint-down(lg) {
            position: relative;
            left: 0px;
            width: auto;
          }
          .attachment{
            @extend .pb-md-2;
            @extend .pb-0;
            border-bottom: 1px solid $primary-light80;
            .fa{
              font-size: 1.9em;
              line-height: 1em;
              color: $primary-light40;
              &:hover{
                color: $second-color;
              }
            }
            @include media-breakpoint-down(lg) {
              border-bottom: 0px;
              .fa{
                margin-right: 10px;
              }
            }
  
          }
          .ssba{
            width: auto;
            &>div{
              text-align: center !important;
            }
            .ssba-share-text, br{
              display: none;
            }
            img {
              filter: none;
              -webkit-filter: grayscale(100%);
              -moz-filter:    grayscale(100%);
              -ms-filter:     grayscale(100%);
              -o-filter:      grayscale(100%);
              cursor: pointer;
              transition: all 300ms ease; 
            }
            img:hover {
              filter: none;
              -webkit-filter: grayscale(0%);
              -moz-filter:    grayscale(0%);
              -ms-filter:     grayscale(0%);
              -o-filter:      grayscale(0%);
            }
          }
        }
      }
      /*
      a{
        color: $second-color;
        text-decoration: underline;
      }
      */
      .red, .red-dm{
        color: $second-color;
      }
      .blue, .blue-dm{
        color: $third-color;
      }
      .green, .green-dm{
        color: $forth-color;
      }
      .lightblue, .lightblue-dm{
        color: $fifth-color;
      }
    }
    .entry-footer{
      a{
        text-decoration: none;
      }
    }
  }

  @mixin band{
    background: $primary;
    color: $primary-light;
    font-size: 24px;
    padding-left: 50px;
    padding-right: 50px;
    @include media-breakpoint-down(md) {
      font-size: 16px;
      padding-left: 15px;
      padding-right: 15px;
    }
    svg{
      max-width: 100%;
      height: auto;
    }
    h3{
      font-size: $font-lg;
      font-weight: $font-w-regular;
      strong{
        color: $second-color;
        font-weight: $font-w-regular;
      }
    }
    h4{
      font-size: $font-default;
      font-weight: $font-w-regular;
      margin-bottom: 0px;
    }
    small{
      font-size: $font-small;
    }
    .btn-link{
      color: $second-color !important;
    }
  }
  
  @mixin link-button{
    display: inline-block;
    @extend .mr-4;
    &:hover{
      text-decoration: none;
    }
    &.danger{
      color: $second-color;
    }
    &.primary{
      color: $third-color;
    }
    &.light{
      color: $primary-light;
    }
  }

@mixin page-cover{
  position: relative;
  img{
    width: 100%;
  }
  header{
    &.entry-header{
      position: absolute;
      width: 100%;
      z-index: 1;
      bottom: 0px;
      left: 0px;
      padding-left: 50px;
      padding-right: 20px;
      padding-bottom: 50px;
      color: $primary-light;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,0.7189250700280112) 0%, rgba(0,0,0,0.39399509803921573) 52%, rgba(0,0,0,0) 80%);
      .entry-title{
        font-size: $font-xlg;
        @include media-breakpoint-down(md) {
          font-size: $font-lg-3;
        }
        @include media-breakpoint-down(sm) {
          font-size: $font-lg-21;
        }
        @extend .pl-3;
        border-left: 2px solid $primary-light;
        @extend .mb-0;
      }
      .entry-meta{
        @extend .pl-3;
        border-left: 2px solid $primary-light;
        .subtitle{
          @extend .pb-1;
          @extend .pb-md-0;
        }
      }
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
      }
      .ancestor{
        font-weight: $font-w-bold;
        text-transform: uppercase;
        @extend .pb-2;
        @extend .pl-3;
        border-left: 2px solid #fff;
        span{
          color: $second-color;
        }
      }
    }
  }
  &.no-cover{
    header{
      &.entry-header{
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 52%, rgba(0,0,0,1) 80%);
      }
    }
  }
}

//Padding
@mixin py-6{
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
@mixin pt-6{
  padding-top: 4rem !important;
}
@mixin pb-6{
  padding-bottom: 4rem !important;
}
@mixin py-7{
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
@mixin pt-7{
  padding-top: 5rem !important;
}
@mixin pb-7{
  padding-bottom: 5rem !important; 
}
@mixin py-8{
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
@mixin pt-8{
  padding-top: 6rem !important;
}
@mixin pb-8{
  padding-bottom: 6rem !important;
}
@mixin px-6{
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
@mixin pl-6{
  padding-left: 4rem !important;
}
@mixin pr-6{
  padding-right: 4rem !important;
}
@mixin px-7{
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
@mixin pl-7{
  padding-left: 5rem !important;
}
@mixin pr-7{
  padding-right: 5rem !important;
}
@mixin px-8{
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}
@mixin pl-8{
  padding-left: 6rem !important;
}
@mixin pr-8{
  padding-right: 6rem !important;
}

//Margin
@mixin my-6{
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
@mixin mt-6{
  margin-top: 4rem !important;
}
@mixin mb-6{
  margin-bottom: 4rem !important;
}
@mixin my-7{
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
@mixin mt-7{
  margin-top: 5rem !important;
}
@mixin mb-7{
  margin-bottom: 5rem !important;
}
@mixin my-8{
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}
@mixin mt-8{
  margin-top: 6rem !important;
}
@mixin mb-8{
  margin-bottom: 6rem !important;
}
@mixin mx-6{
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
@mixin ml-6{
  margin-left: 4rem !important;
}
@mixin mr-6{
  margin-right: 4rem !important;
}
@mixin mx-7{
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}
@mixin ml-7{
  margin-left: 5rem !important;
}
@mixin mr-7{
  margin-right: 5rem !important;
}
@mixin mx-8{
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}
@mixin ml-8{
  margin-left: 6rem !important;
}
@mixin mr-8{
  margin-right: 6rem !important;
}