
.knockout-text{
  background-color: #fff;
  overflow: hidden;
  .clip-text {
    position: relative;
    display: inline-block;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .clip-text--cover{
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
  }
}

/* Slow-motion Zoom  */
.img-hover-zoom--slowmo img {
  transform-origin: 50% 50%;
  transition: transform 30s, filter 4s ease-in-out;
  -webkit-transition: transform 30s, filter 4s ease-in-out;
  -moz-transition: transform 30s, filter 4s ease-in-out;
  -o-transition: transform 30s, filter 4s ease-in-out;
}
.img-hover-zoom--slowmo:hover img {
  transform: scale(1.3);
}
.img-hover-zoom img {
  transform-origin: 50% 50%;
  transition: transform 5s, filter 4s ease-in-out;
  -webkit-transition: transform 5s, filter 4s ease-in-out;
  -moz-transition: transform 5s, filter 4s ease-in-out;
  -o-transition: transform 5s, filter 4s ease-in-out;
}
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
.img-box{
  overflow: hidden;
}
/* up */
.image-up-box img{
  transition: transform 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
}
.image-up-box:hover img{
  transform: translate(0, -20px);
}
.image-up{
  transition: transform 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
}

.image-up:hover {
  transform: translate(0, -20px);
}
.img-hover-colorize {
  transition: filter 1.5s;
  transition: filter 1.5s;
  -webkit-transition: filter 1.5s;
  -moz-transition: filter 1.5s;
  -o-transition: filter 1.5s;
  filter: grayscale(100%);
}

/* The Transformation */
.img-hover-colorize:hover{
  filter: grayscale(0);
}