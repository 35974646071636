// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
body{
  font-size:16px;
  font-weight: $font-w-regular;
}
@include media-breakpoint-down(sm) {
  h3{
    font-size:$font-lg-2;
  }
}
#dg-footer-zone{
  position:relative;
  .bg{
    position: relative;
    width:100%;
    z-index:100;
  }
  .text{
    @include media-breakpoint-up(md) {
      position:absolute;
      z-index:300;
      bottom:36px;
      color:$primary-light;
      padding-right:$padding-section-right;
      padding-left:$padding-section-left;
    }
    @include media-breakpoint-down(md) {
      padding-right:$padding-section-right-sm;
      padding-left:$padding-section-left-sm;
    }
    h3{
      font-size: $font-lg;
      margin-bottom:23px;
      @include media-breakpoint-down(md) {
        margin-top:23px;
      }
    }
  }
}
#service-footer-zone{
  display: none;
  margin-top:40px;
  background-image: url(../img/bg-footer.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include section;
  @include py-7;
  color: $primary-light;
  h3{
    font-size: $font-lg-3;
    font-weight: $font-w-bold;
    line-height: 0.7;
    margin-right: 40px;
    @include media-breakpoint-down(md) {
      margin-right: 0px;
    }
    span{
      color: $second-color;
    }
  }
}

#faq{
  margin-top:50px;
  .entry-title{
    display: none;
  }
  #content {
    padding-top: 0px;
  } 
  #accordion{
    .card{
      a{
        color:$primary;
      }
      a[aria-expanded="false"]{
        .fa-plus{
          display: inline;
        }
        .fa-minus{
          display: none;
        }
      }
      a[aria-expanded="true"]{
      .fa-plus{
          display: none;
        }
        .fa-minus{
          display: inline;
        }
      }
      .card-header{
        font-weight: $font-w-semibold;
        font-size: $font-medium;
        p, span{
          margin:0;
          display: inline-block;
        }
        span.ico{
          float:right;
          color:$second-color;
        }
      }
    }
  }
}

.home, .page-template-page-portfolio-startup{
  #service-footer-zone{
    display: block;
  }
}
.home{
  .container-footer-sidebar{
    .widget_general-banner{
      display: none;
    }
  }
}

.tit-page{
  background-color: $primary;
  padding:80px 0 24px 0;
}