footer{
  padding-top:100px;
  padding-bottom:100px;
  background: $primary-light95;
  color:$primary-light20;
  overflow-x: hidden;
  @extend .px-0;
  a{color:$primary-light20;}
  .p-footer{
    padding-top:58px;
  }
  .claim{
    font-weight:$font-w-semibold;
  }
  .social{
    font-size: $font-lg;
    .fa-linkedin-square, .fa-twitter, .fa-facebook-official{
      color:$primary-light20;
    }
    .fa-instagram{
      background: none;
      -webkit-text-fill-color:initial;
    }
    .fa-arrow-up{
      color:$second-color;
    }
  }
  .menu{
    font-size:$font-xsmall-1;
  }
  .info{
    font-size:$font-xsmall-1;
    .logo-bcorp-credits{
      svg{
        width: 1%; 
        height: auto;
        @include media-breakpoint-down(sm) {
          width: 4%;
        }
      }
    }
  }
  .logo-top-footer, .logo-bcorp-top-footer{
    svg{
      max-width: 100%;
      height: auto;
    }
  }
  .logo-bcorp-top-footer {
    svg{
      width: 65%;
      @include media-breakpoint-down(sm) {
        width: auto; 
      }
    }
  }
}