.wrapper-evento{
  background: rgb(255,255,255);
  background: linear-gradient( 90deg, rgba(255,255,255,1) 70%, rgba(243,243,243,1) 70%);
  @include media-breakpoint-down(sm) {
    background: linear-gradient( 90deg, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 70%);
  }
}
.single{
  .single-cover{
    @include page-cover;
    .entry-header{
      padding-top: 130px;
    }
  }
  .single-cover-evento{
    @include page-cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include py-7;
    header{
      &.entry-header{
        position: relative;
        top: 0px;
        padding-right: 50px;
        padding-top: 50px;
        background: none;
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-center;
        @extend .align-items-center;
        .entry-title{
          border-left: 0px;
        }
      }
      img{
        width: auto;
        max-width: 100%;
        @extend .mb-4;
      }
      .abstract{
        font-size: $font-lg;
        font-weight: $font-w-bold;
        @include pb-6;
      }
      h3{
        font-size: $font-xlg-1;
        font-weight: $font-w-bold;
        @extend .mb-3;
        @include media-breakpoint-down(sm) {
          font-size: $font-lg-21;
        }
        span{
          color: $second-color;
        }
      }
    }
  }
  #content{
    @include inner-content;
    .site-main{
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      a{
        color: $second-color;
        text-decoration: underline;
      }
    }
    .infobox{
      @extend .mt-3;
      @extend .mb-5;
      .info{
        color: $primary-light40;
        font-size: $font-small;
        @extend .px-3;
        &:first-child{
          @extend .pl-0;
        }
        .ev{
          color: $second-color;
          font-weight: $font-w-semibold;
        }
      }
      &.references, &.top-info{
        @extend .d-flex;
        text-transform: uppercase;
      }
      &.references{
        @extend .flex-column;
        @extend .flex-sm-row;
        
        .reference{
          @extend .d-flex;
          @extend .py-2;
          @extend .py-md-0;
          @extend .justify-content-center;
          @extend .justify-content-sm-start;
          &.website{
            #globe{
              width: 30px;
              height: auto;
              @extend .ml-4;
              .svg-globe{
                fill: $primary-light70;
              }
            }
            a{
              text-decoration: none;
              font-weight: $font-w-semibold;
              margin-left: 15px;
              .fa{
                margin-left: 10px;
              }
            }
          }
          &.social{
            @extend .justify-content-md-start;
            .social-row{
              @extend .d-flex;
              list-style: none;
              @extend .align-items-center;
              @extend .mb-0;
              @extend .pl-3;
              li{
                a{
                  .fa{
                    @extend .px-0;
                    @extend .mx-1;
                    font-size: $font-lg;
                    color: $second-color;
                    &.fa-instagram{
                      -webkit-text-fill-color:$second-color; 
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    footer{
        &.entry-footer{
            background: transparent;
            @extend .py-0;
        }
    }
    .btn{
      &.btn-danger{
          color: $primary-light;
      }
    }
    .entry-content{
      :is(h1, h2, h3, h4, h5, h6){
          margin-top: 80px;
      }
    }
    #container-bradcrumbs{
      padding-top: 60px;
      padding-bottom: 60px;
      .container{
        padding-left: 0px !important;
        a{
          text-decoration: none;
        }
      }
    }
    &.container-evento{
      @extend .py-0;
      .event-right, .event-left{
        padding-bottom: 100px;;
      }
      .event-right{
        background: $primary-light95;
        margin-top: -20px;
        z-index: 10;
        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
        .box-evento{
          background-color: #e2e2e2;
          a{
            text-decoration: none;
            &.btn-outline-danger:hover{
              color: $primary-light;
            }
          }
        }
      }
    }
  }
  .entry-header{
    .claim{
      font-size: $font-lg;
      font-weight: $font-w-semibold;
    }
    .subtitle{
      font-size: $font-lg;
    }
  }
  &.single-startup{
    .single-cover{
      &.no-cover{
        @extend .d-none;
        @extend .d-md-block;
        header{
          &.entry-header{
            padding-bottom: 0px;
            padding-top: 110px;
          }
        }
      }
    }
    #content{
      padding-bottom: 0px;
    }
    .highlights-container{
      @include band;
      background-image: url(../img/bg-attachment-box.png);
      background-position: right center;
      background-size: cover;
      @extend .mb-5;
      @include py-7;
      h3{
        font-size: $font-lg-3;
        font-weight: $font-w-semibold;
        strong{
          font-weight: $font-w-semibold;
        }
      }
      .link{
        color:$second-color;
      }
    }
    #company-zone{
      margin-top: 0px;
    }
    .all-startups-container{
      padding-left: 50px;
      padding-right: 50px;
      @include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;
      }
      .knockout-text{
        @extend .text-center;
        .clip-text{
          font-size:$font-xxlg;
          font-weight:$font-w-semibold;
          line-height: $font-xxlg;
          @include media-breakpoint-down(sm) {
            font-size:$font-xlg;
            line-height: $font-xlg;
          }
          &.clip-text_startup {
              background-image: url(https://media.giphy.com/media/krQzJaxbU1aXS/giphy.gif);
          }
        }
      }
      .all-startups{
        list-style: none;
        padding: 30px 0px 0px 0px;
        column-count: 4;
        column-gap: 30px;
        @include media-breakpoint-down(md) {
          column-count: 2;
        }
        li{
          margin-bottom: 10px;
        }
      }
    }
  }
  &.single-team{
    .single-cover{
      header.entry-header{
        position: relative;
        background-color: $primary; 
        .entry-title{
          font-size: $font-lg-5;
          font-weight: $font-w-bold;
        }
        .subtitle{
          font-size: $font-default;
        }
      }
    }
    #content{
      @include media-breakpoint-down(sm) {
        padding-bottom: 60px;
      }
      .infobox{
        font-size: $font-small;
        text-transform: uppercase;
        color: $primary-light20;
        @extend .mt-lg-3;
        @extend .mb-lg-5;
        @extend .mt-1;
        @extend .mb-3; 
        span{
          font-weight: $font-w-semibold;
          color: $second-color;
        }
      }
    }
  }
}

.archive, .page{
  .single-cover{
    @include page-cover;
  }
  &.post-type-archive-startup, &.page-template-page-portfolio-startup, &.page-template-page-portfolio-startup-perks{
    .content-area{
      @extend .px-0;
    }
    .content-section{
      @extend .pt-0;
      @extend .pb-3;
      .subtitle{
        font-size: $font-lg;
        font-weight: $font-w-semibold;
      }
      .text{
        @extend .mt-4;
      }
    }
    .knockout-text{
      @extend .text-center;
      .clip-text{
        font-size:$font-xxlg;
        font-weight:$font-w-semibold;
        line-height: $font-xxlg;
        @include media-breakpoint-down(sm) {
          font-size:$font-xlg;
          line-height: $font-xlg;
        }
        &.clip-text_startup {
            background-image: url(https://media.giphy.com/media/krQzJaxbU1aXS/giphy.gif);
        }
      }
    }
    .highlights-container{
      @include band;
      @include py-7;
      background-image: url(../img/portfolio-attachment-startup-bg.jpeg);
      background-position: right center;
      background-size: cover;
      header{
        h3{
          font-size:$font-lg-3;
          font-weight:$font-w-bold;
          @include media-breakpoint-down(md) {
            font-size:$font-lg;
          }
          span{
            color:$second-color;
          }
        }
      }
      .logo{
        svg{
            width: 150px;
            .text-svg{
                fill: $primary-light;
            }
        }
      }
      .ico{
        svg{
          width: 16px;
        }
      }
      .link{
        color:$second-color;
      }
    }
    .container-startup-page{
      .content{
        padding-top: 5rem;
        padding-bottom: 5rem;
        font-size: 1.125em;
      }
    }
    .band-startup{
      @include band;
      @extend .mb-0;
      font-size: .875em;
      a{
        color: $primary-light;
      }
      .description{
        color: $second-color;
        h1, h2, h3, h4{
          color: $primary-light;
        }
      }
      .info{
        font-size: 1.125em;
        a{
          color: $second-color;
        }
      }
    }
    .App{
      @extend .px-3;
      @extend .px-md-0;
    }
    /*.row-container-startup-sort{
      @extend .py-3;
      @extend .mb-5;
      .container-startup-sort{
        @extend .container;
        .row-startup-sort{
          @extend .row;
          //@extend .justify-content-end;
          //@extend .w-100;
          text-transform: uppercase;
          color: $primary-light20;
          .search, .row-select{
            @extend .col-12;
            @extend .d-flex;
          }
          .row-select{
            @extend .col-lg;
            @extend .justify-content-end;
            @extend .w-100;
            @extend .flex-column;
            @extend .flex-lg-row;
          }
          .search{
            @extend .col-lg-3;
            .input-group{
              width: auto;
              @extend .mb-3;
              @extend .mb-0;
              @include media-breakpoint-down(lg) {
                width: 100%;
              }
              input{
                border: 0px;
                border-radius: 0px;
                border-bottom: 2px solid $primary-light20;
                padding: 0rem 0rem;
                &:focus{
                  -webkit-box-shadow: 0 0 0 0rem ;
                  box-shadow: 0 0 0 0rem ;
                }
                &:focus-visible{
                  outline-color: transparent;
                }
              }
              .input-group-text{
                background-color: transparent;
                border: 0px;
                border-radius: 0px;
                background-image: url(../img/search-lens.png);
                background-position: center center;
                background-repeat: no-repeat;
                width: 23px;
                @extend .mr-3;
              }
            }
          }
          .select-container{
            //@extend .col-md-2;
            //max-width: 12%;
            @extend .d-flex;
            @extend .align-items-center;
            @extend .mr-0;
            @extend .mr-lg-3;
            @include media-breakpoint-down(lg) {
              //max-width: 16%;
            }
            @include media-breakpoint-down(md) {
              max-width: 100%;
            }
            .custom-selector{
              width: 125px;
              &>div{
                //background: $primary-light-to-gray;
                border: 0px;
                border-radius: 0px;
                &>div{
                  &>div{
                    color: $second-color;
                    font-weight: $font-w-bold;
                  }
                  &:nth-child(2){
                    span{
                      display: none;
                    }
                  }
                  svg{
                    color: $primary-light20;
                  }
                }
              }
            }
            &.select-order{
              //@extend .col-md-3;
              //max-width: 19%;
              white-space: nowrap;
              @include media-breakpoint-down(lg) {
                //max-width: 23%;
              }
              @include media-breakpoint-down(md) {
                max-width: 100%;
              }
              &>div{
                border: 0;
                border-radius: 0;
              }
              .custom-selector{
                width: 230px;
              }
            }
            &:last-child{
              @extend .mr-0;
            }
          }
        }
      }
    }
    */
    .row-container-startup-sort{
      @extend .py-3;
      @extend .mb-5;
      .container-startup-sort{
        @extend .container;
        .row-startup-sort{
          @extend .row;
          font-size: $font-small;
          text-transform: uppercase;
          color: $primary-light20;
          @extend .align-items-center;
          .search{
            @extend .col-lg-2;
            @extend .col-12;
            .input-group{
              width: auto;
              @extend .mb-3;
              @extend .mb-0;
              @include media-breakpoint-down(lg) {
                width: 100%;
              }
              input{
                border: 0px;
                border-radius: 0px;
                border-bottom: 2px solid $primary-light20;
                padding: 0rem 0rem;
                &:focus{
                  -webkit-box-shadow: 0 0 0 0rem ;
                  box-shadow: 0 0 0 0rem ;
                }
                &:focus-visible{
                  outline-color: transparent;
                }
              }
              .input-group-text{
                background-color: transparent;
                border: 0px;
                border-radius: 0px;
                background-image: url(../img/search-lens.png);
                background-position: center center;
                background-repeat: no-repeat;
                width: 23px;
                @extend .mr-3;
              }
            }
          }
          .row-select{
            @extend .col-lg-10;
            @extend .col-12;
            @extend .d-flex;
            @extend .justify-content-end;
            @extend .flex-column;
            @extend .flex-lg-row;
            .select-container{
              @extend .d-flex;
              @extend .align-items-center;
              @extend .mr-0;
              @extend .mr-lg-3;
              text-align: left;
              .custom-selector{
                width: 125px;
                &>div{
                  border: 0px;
                  border-radius: 0px;
                  &>div{
                    &>div{
                      color: $second-color;
                      font-weight: $font-w-bold;
                    }
                    &:nth-child(2){
                      span{
                        display: none;
                      }
                    }
                    svg{
                      color: $primary-light20;
                    }
                  }
                }
              }
              &.select-order, &.select-order-by{
                //@extend .col-md-3;
                //max-width: 19%;
                white-space: nowrap;
                @include media-breakpoint-down(lg) {
                  //max-width: 23%;
                }
                @include media-breakpoint-down(md) {
                  max-width: 100%;
                }
                &>div{
                  border: 0;
                  border-radius: 0;
                }
              }
              &.select-order{
                .custom-selector{
                  width: 160px;
                }
              }
              &.select-order-by{
                .custom-selector{
                  width: 210px;
                }
              }
              &.select-orderby{
                span{
                  white-space: nowrap;
                }
              }
              &:last-child{
                @extend .mr-0;
              }
            }
          }
        }
      }
    }
  }
  &.page-template-page-portfolio-startup, &.page-template-page-portfolio-startup-perks{
    .content-section{
      @extend .pt-0;
      @extend .pb-3;
      text-align: initial !important;
    }
  }
  &.page-template-page-portfolio-startup-perks{
    .row-container-startup-sort {
      .container-startup-sort {
        .row-startup-sort {
          .row-select {
            .select-container {
              &.select-orderby {
                .custom-selector>div>div:first-child>div[class$="placeholder"] {
                  visibility: hidden;
                  position: relative;
                }
                .custom-selector>div>div:first-child>div[class$="placeholder"]:after {
                  visibility: visible;
                  position: absolute;
                  top: 13px;
                  left: 0;
                  content: "categorie";
                }
              }
            }
          }
        }
      }
    }
  }
  .page-header{
    @include pt-7;
    h1{
      font-size: $font-xxlg;
      @include media-breakpoint-down(md) {
        font-size: $font-xlg;
      }
    }
    h2{
      font-size: $font-lg;
    }
  }
}
.page{
  &.page-parent, &.page-child{
    .wrapper{
      background: rgb(255,255,255);
      background: linear-gradient( 90deg, rgba(255,255,255,1) 70%, rgba(243,243,243,1) 70%);
      @include media-breakpoint-down(sm) {
        background: linear-gradient( 90deg, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 70%);
      }
      &.page-no-menu{
        background: linear-gradient( 90deg, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 70%);
      }
    }
    &.page-template-page-custom, &.page-template-page-how-we-work, &.page-template-page-portfolio-startup, &.page-template-page-portfolio-startup-perks{
      .wrapper{
        background: linear-gradient( 90deg, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 70%);
      }
    }
  }
  #content{
    @include inner-content;
    .site-main a{
      &.btn-danger{
        color: $primary-light;
        text-decoration: none;
      }
    }
    &.has-parent{
      @extend .py-0;
      .site-main, .related-section{
        padding-bottom: 120px;
        #container-bradcrumbs{
          padding-top: 60px;
          padding-bottom: 60px;
          .container{
            padding-left: 0px !important;
          }
        }
      }
      .related-section{
        padding-top: 120px;
      }
      article{
        padding-top: 60px;
      }
    }
  }
  &.page-template-default{
    .site-main{
      padding-right: 30px;
      @include media-breakpoint-down(md) {
        padding-right: 15px;
      }
      a{
        color: $second-color;
      }
    }
  }
  .section-title{
    background: $primary;
    color: $primary-light;
    @extend .pb-4;
    @include pt-8;
    h1{
      font-size: $font-xlg;
      font-weight: $font-w-bold;
      @include media-breakpoint-down(md) {
        font-size: $font-lg-21;
      }
    }
    .ancestor{
      font-weight: $font-w-bold;
      text-transform: uppercase;
      @extend .pb-2;
      span{
        color: $second-color;
      }
    }
    @include media-breakpoint-down(md) {
      padding-left: 1em !important;
      padding-right: 1em !important; 
    }
  }
  .related-section{
    background-color: $primary-light95;
    .title-page, .parents-pages{
      @extend .px-4;
      h4{
        font-weight: $font-w-bold;
        text-transform: uppercase;
        @extend .mb-4;
        span{
          color: $second-color;
        }
      }
      h5{
        font-size: $font-default;
        font-weight: $font-w-regular;
      }
      .parent-page{
        @extend .mb-1;
        a:hover{
          text-decoration: none;
          color: $second-color;
        }
        li{
          list-style: none;
          margin-left: 15px;
        }
      }
    }
  }
  &.page-template-page-portfolio-startup, &.page-template-page-portfolio-startup-perks{
    #content{
      .site-main a{
        text-decoration: none;
      }
    }
  }
  &.page-template-page-custom{
    #content{
      @include media-breakpoint-down(md) {
        padding-left: 0rem!important;
        padding-right: 0rem!important;
      }
    }
    #content.container-page-custom{
      padding-bottom: 0px;
    }
    .widget-row{
      .homepage-widget{
        display: none;
        &.widget_prize{
          display: block;
        }
      }
    }
  }
  &.page-template-page-team-investor{
    #content.has-parent article{
      padding-top: 0px;
    }
    .category-member{
      .cat-header{
        @extend .mt-5;
        h3{
          font-size: $font-lg;
          font-weight: $font-w-semibold;
          color: $second-color;
        }
      }
      .member{
        @extend .d-flex;
        a{
          flex: 1;
          background-color: $primary-light95;
          .content-member{
            img{
              filter: grayscale(0%);
              -webkit-filter: grayscale(0%);
              -moz-filter: grayscale(0%);
            }
            .title{
              @extend .p-3;
              .name{
                font-size: $font-medium;
                font-weight: $font-w-semibold;
                color: $second-color;
              }
            }
          }
          &:hover{
            text-decoration: none;
            .content-member{
              img{
                filter: grayscale(0);
                -webkit-filter: grayscale(0);
                -moz-filter: grayscale(0);
                filter: brightness(63%);
                -webkit-filter: brightness(63%);
                -moz-filter: brightness(63%);
              }
              .title-member{
                top: 55px;
              }
            }
          }
        }
      }
    }
    .title-section{
      font-size: $font-lg;
      font-weight: $font-w-semibold;
      color: $second-color;
      @include pt-6;
      @extend .pb-4;
    }
    .attachments{
      @extend .pl-0;
      list-style: none;
      .attachment{
        @extend .pb-2;
        @extend .mb-4;
        border-bottom: 1px solid $primary-light81;
        &:last-child{
          border-bottom: 0px;
        }
        a:hover{
          text-decoration: none ;
        }
        .fa{
          color: $second-color;
        }
        .content-col{
          font-size: $font-medium;
          font-weight: $font-w-semibold;
        }
      }
    }
  }
  &.page-template-page-press-investor{
    .select-tab-content{
      .sezione-contenuto{
        .content{
          @extend .pb-2;
          @extend .mb-3;
          .fa{
            margin-top: 0px;
          }
        }
      }
    }
  }
  &.page-template-page-sustainability{
    .header-page{
      margin-top: -15%;
      h3{
        font-size: $font-lg;
      }
      h2{
        font-size: $font-lg-4;
        font-weight: $font-w-semibold;
      }
    }
    .citazione-container{
      .ico-quote{
        svg{
          max-width: 100%;
          height: auto;
        }
      }
    }
    .numeri{
      .numero-container{
        @extend .d-flex;
        @extend .flex-column;
        @extend .mb-3;
        .numero-box{
          @extend .p-3;
          flex: 1;
          background: rgb(0,159,226);
          background: linear-gradient(180deg, rgba(0,159,226,1) 0%, rgba(0,155,105,1) 100%);
          color: $primary-light;
          text-transform: uppercase;
          font-weight: $font-w-bold;
          @include media-breakpoint-down(lg) {
            font-size: $font-small;
          }
          .ico{
            min-height: 110px;
            @extend .d-flex;
            @extend .justify-content-end;
          }
          .numero{
            font-family: $second-font;
            font-size: $font-xxlg;
            font-weight: $font-w-regular;
            line-height: 1;
            @include media-breakpoint-down(lg) {
              font-size: $font-xlg;
            }
          }
        }
      }
    }
    .bilanci{
      .box-download{
        display: block;
        position: relative;
        .hover-description{
          position: absolute;
          z-index: 1;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          @extend .p-3;
          @extend .d-flex;
          @extend .justify-content-center;
          @extend .align-items-center;
          visibility: hidden;
          svg{
            max-width: 100%;
          }
        }
        .title{
          background: rgb(0,159,226);
          background: linear-gradient(270deg, rgba(0,159,226,1) 0%, rgba(0,155,105,1) 100%);
          @extend .py-2;
          @extend .px-3;
          font-size: $font-medium;
          font-weight: $font-w-semibold;
          color: $primary-light; 
        }
        &:hover{
          text-decoration: none;
          color: $primary-light;
          .hover-description{
            visibility: visible;
          }
          img{
            filter: brightness(50%);
          }
        }
      }
    }
  }
}
*:lang(en-US) {
  .page{
    &.page-template-page-portfolio-startup-perks {
        .row-container-startup-sort {
          .container-startup-sort {
            .row-startup-sort {
              .row-select {
                .select-container {
                  &.select-orderby {
                    .custom-selector{
                      width: 140px;
                    }
                    .custom-selector>div>div:first-child>div[class$="placeholder"]:after {
                      content: "categories"; 
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
}