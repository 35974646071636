  .progressBarContainer {
    position: absolute;
    bottom: 70px;
    width:300px;
    left:50px;
    @include media-breakpoint-down(md) {
      width:200px;
      bottom: 35px;
    }
    @include media-breakpoint-down(sm) {
      width:95%;
      left:15px;
      bottom: 25px;
    }
  }
  
  .progressBarContainer{
    div {
      display: block;
      width: 30%; 
      padding: 0;
      cursor: pointer;
      margin-right: 5%;
      float: left;
      color: white;
    }
    @include media-breakpoint-down(sm) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      div{
        width: auto;
        flex: 1;
        float: none;
      }
    }
  }
  
  .progressBarContainer div:last-child {
    margin-right: 0;
  }
  
  .progressBarContainer div span.progressBar {
    width: 100%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.9);
    display: block;
    @include media-breakpoint-down(sm) {
      height: 2px;
    }
  }
  
  .progressBarContainer div span.progressBar .inProgress {
    background-color: $second-color;
    width: 0%;
    height: 4px;
    @include media-breakpoint-down(sm) {
      height: 2px; 
    }
  }
  

// Design Slider
.slider, .slide {
  @media (min-width: 992px) {
    //height: 70vh;
  }
  @media only screen and (max-height: 900px) {
    height: 100vh; 
    overflow: hidden;
  }
}

.slide {
  position: relative;
	transition:1s;
  font-size: $font-medium;
  .slide__img {
    @media (min-width: 992px) {
      /*position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);*/
    }
    width: 100%;
    height: auto;
    overflow: hidden;
    img {
      //max-width: 100%;
      width: 100%;
      height: auto;
      opacity: 1 !important;
      animation-duration: 3s;
      transition: all 1s ease;
    }
  }
  .slide__content { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @extend .d-flex;
    height: 100%;
    //background: rgb(0, 0, 0); 
    //background: linear-gradient(62deg, rgba(0, 0, 0, 0.34499737394957986) 17%, rgba(0, 0, 0, 0.042476365546218475) 100%);
    &.container-fluid{
      @include media-breakpoint-up(md) {
      padding-right:$padding-section-right;
      padding-left:$padding-section-left;
      }
      @include media-breakpoint-down(md) {
      padding-right:$padding-section-right-sm;
      padding-left:$padding-section-left-sm;
      }
    }
		&.slide__content__left{
			left: 15%;
			transform: translate(-15%, -50%);
		}
		&.slide__content__right{
			right: 15%;
			left: auto;
			transform: translate(5%, -50%);
		}
    &--headings {
      color: #FFF;
      h2 {
        font-size: 4.5rem;
        margin: 10px 0;
      }
      .animated {
        transition: all .5s ease;
        @include media-breakpoint-down(md) {
          transition: none;
        }
      }

			.title{
				font-size: $font-lg-3;
        font-weight: $font-w-bold;
        span{
          color: $second-color;
        }
        @include media-breakpoint-down(md) {
          font-size: $font-lg-2;
        }
			}
      .top-title{
        margin-bottom: 1.3rem;
        @media only screen and (max-height: 900px) {
          margin-bottom: 0.5rem;
        }
      }
			
    }
    .btn{
      @include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: $font-small;
      }
    }
    .row-slide{
      @extend .align-items-xl-end;
      @extend .align-items-sm-center;
      @extend .align-items-start;
      width: 100%;
      @extend .pt-md-0;
      @extend .pt-5;
      /*
      @media only screen and (max-height: 900px) {
        -webkit-box-align: center!important;
        -webkit-align-items: center !important;
        -moz-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important;
      }
      */
      .col-slide{
        margin-bottom: 150px;
        @extend .col-xl-6;
        @extend .col-md-12;
        @include media-breakpoint-down(xl) {
          margin-bottom: 90px; 
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 40px;
        }
        @media only screen and (max-height: 900px) {
          -webkit-box-flex: 0 !important;
          -webkit-flex: 0 0 100% !important;
          -moz-box-flex: 0 !important;
          -ms-flex: 0 0 100% !important;
          flex: 0 0 100% !important;
          max-width: 100% !important;
          .button-section, .top-title.bottom-side{
            padding-top: .50rem !important;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      img{
        width: 50%;
      }
    }
  }
  &.light-slide{
    .slide__content--headings{
      color: $primary; 
    }
  }
}

// Change animation presets
.slider [data-animation-in] {
    opacity: 0;
    animation-duration: 1.5s;
    transition: opacity 0.5s ease 0.3s;
	  transition:1s;
}

// Dots
.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            display: block;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            transition: all .3s ease;
            &:hover, &:focus {
                outline: none;
            }
        }
    }
}


/* /////////// IMAGE ZOOM /////////// */
@keyframes zoomInImage {
  from {
    transform: scale3d(1, 1, 1);
		transition:1s;
  }
  to {
    transform: scale3d(1.1, 1.1, 1.1);
			transition:1s;
  }
}

.zoomInImage {
  animation-name: zoomInImage;
}

@keyframes zoomOutImage {
  from {
    transform: scale3d(1.1, 1.1, 1.1);
			transition:1s;
  }
  to {
    transform: scale3d(1, 1, 1);
			transition:1s;
  }
}

.zoomOutImage {
  animation-name: zoomOutImage;
		transition:1s;
}


.slider-startup, .slider-companies{
  .slick-dots {
    position:initial;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li button {
      background: $primary-light-to-gray1;
      padding: 2px 16px;
    }
    li.slick-active button {
      background: $second-color;
      padding: 2px 16px;
    }
  }
}

.slider-cover{
  height: 80vh;
  overflow-y: hidden ;
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }
  @media only screen and (max-height: 900px) {
    height: 100vh;
  }
  .slide .slide__img img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    @include media-breakpoint-down(sm) {
      height: 100vh;
    }
    @media only screen and (max-height: 900px) {
      height: 100vh;
    }
  }
}