// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.

// Poppins family font
@font-face {
  font-family: 'Poppins-Regular';
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}


// Bebas Neue family font
@font-face {
  font-family: 'Bebas-Neue';
  src: url("../fonts/Bebas_Neue/BebasNeue-Regular.ttf") format("ttf");
  src: url("../fonts/Bebas_Neue/BebasNeue-Regular.otf") format("otf");
  src: url("../fonts/Bebas_Neue/BebasNeue-Regular.eot") format("eot");
  src: url("../fonts/Bebas_Neue/BebasNeue-Regular.woff") format("woff");
  src: url("../fonts/Bebas_Neue/BebasNeue-Regular.woff2") format("woff2");
}

/*fonts*/
$primary-font:'Poppins-Regular', sans-serif; 
$second-font:'Bebas-Neue', cursive;

strong{
  font-weight: 700;
}

$font-default:1rem; /*16*/
$font-xsmall:0.6875rem; /*11*/
$font-xsmall-1:0.75rem; /*12*/
$font-small:0.875rem; /*14*/
$font-small-1:0.9375rem; /*15*/
$font-medium:1.125rem;  /*18*/
$font-lg-2:1.3125rem; /*21*/
$font-lg:1.5rem; /*24*/
$font-lg-21:1.75rem; /*28*/
$font-lg-3:2.25rem; /*36*/
$font-lg-4:2.375rem; /*38*/
$font-lg-5:2.75rem; /*44*/
$font-xlg:3rem; /*48*/
$font-xlg-1:3.25rem; /*52*/
$font-xlg-2:4rem; /*64*/
$font-xxlg:4.5rem; /*72*/
$font-xxlg-2:6rem; /*96*/

$font-size-Knockout: 130px; 

$font-w-light:300;
$font-w-regular:400;
$font-w-medium:500;
$font-w-semibold:600;
$font-w-bold:700; 
$font-w-xbold:800;

$primary: #000000; // Thats a sample how you could change a BootStrap variable.
  $primary-light95: lighten($primary, 95.29); //#F3F3F3
  $primary-light90: lighten($primary, 93.73); //#efefef
  $primary-light81: lighten($primary, 89.80); //#E5E5E5
  $primary-light80: lighten($primary, 80.00); //#cccccc
  $primary-light70: lighten($primary, 73.73); //##bcbcbc
  $primary-light60: lighten($primary, 60.00); //#999999
  $primary-light60-1: rgba(6, 15, 38, 0.6); //#060F26 60% 
  $primary-light50s: rgba(137, 150, 159, 1); //#89969F
  $primary-light40: lighten($primary, 40.00); //#666666
  $primary-light20: lighten($primary, 20.00); //#333333
  $primary-lightest: lighten(saturate(adjust-hue($primary, 180), 4.00), 95.10); //#f2f3f3
  $primary-light: #ffffff;
  $primary-light-to-gray: darken($primary-light, 1.96); //#fafafa
  $primary-light-to-gray1: darken(saturate(adjust-hue($primary-light, 180), 4.00), 4.90); //#f2f3f3

$second-color: #C61A27; //red DM
$third-color: #1882c3; //blue DM
$forth-color: #3F983D; // green DM
$fifth-color: #7AB0B8; //blumarine DM

$padding-section-left: 50px;
$padding-section-right: 50px;
$padding-section-left-sm: 20px;
$padding-section-right-sm: 20px;
