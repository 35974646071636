#innovation-zone{
  @include media-breakpoint-up(md) {
    padding-right:$padding-section-right;
    padding-left:$padding-section-left;
  }
  @include media-breakpoint-down(md) {
    padding-right:$padding-section-right-sm;
    padding-left:$padding-section-left-sm;
  }
  padding-bottom: 100px;
  padding-top: 50px;
  background-color: $primary;
  //z-index:4000;
  position: relative;
  color: $primary-light;
  .text1{
    font-size:$font-lg;
    font-weight: $font-w-bold;
    span{
      color: $second-color;
    }
  }
  .text2{
    @extend .pb-3;
  }
  p{
    margin:0;
  }
  .knockout-text{
    .clip-text{
      font-size:$font-size-Knockout;
      font-weight:$font-w-semibold;
      line-height: $font-size-Knockout;
      @media (max-width: 1360px){
        font-size:$font-xxlg-2;
        line-height: $font-xxlg-2;
      }
      @include media-breakpoint-down(sm) {
        font-size:$font-xlg;
        line-height: $font-xlg;
      }
      &.clip-text_innovation {
          background-image: url(https://media.giphy.com/media/U7yRCYz2b36RTPOS1R/giphy.gif);
          //background-image: url(https://media.giphy.com/media/3o7TKL943iNs6Uys5G/giphy.gif);
      }
    }
  }
  .info-number{
    margin-bottom:45px;
    @include media-breakpoint-down(sm) {
      margin-top:45px;
      margin-bottom:0;
    }
    .number{
      font-family: $second-font;
      color:$second-color;
      font-size:144px;
      line-height:$font-xxlg;
      @media (max-width: 1600px){
        font-size: 95px;
      }
      @include media-breakpoint-down(lg) {
        margin-top:20px;
        font-size: 144px;
      }
      @include media-breakpoint-down(md) {
        
        margin-top: 20px;
      }
      small{
        font-size:$font-xlg;
      }
    }
    .text{
      text-transform: uppercase;
      line-height: 1.3;
      .unit{
        color: $second-color;
        font-weight:$font-w-bold;
        font-size: $font-lg;
        text-transform: initial;
      }
    }
    @include media-breakpoint-only(md) {
      /*margin-top:45px;
      .number{
        font-size:$font-lg-4;
        line-height:$font-lg-4;
        small{
          font-size:$font-lg;
        }
      }
      .text{
        font-size:$font-small;
      }*/
    }
  }
}

/* zona startup */

#startup-zone, #company-zone{
  @include media-breakpoint-up(md) {
    padding-right:$padding-section-right;
    padding-left:$padding-section-left;
  }
  @include media-breakpoint-down(md) {
    padding-right:$padding-section-right-sm;
    padding-left:$padding-section-left-sm;
    .btn{
      margin-bottom:20px;
    }
  }
  margin-top:100px;
  .knockout-text{
    .clip-text{
      font-size:$font-xxlg;
      font-weight:$font-w-semibold;
      line-height: $font-xxlg;
      @include media-breakpoint-down(sm) {
        font-size:$font-xlg;
        line-height: $font-xlg;
      }
      &.clip-text_startup {
          background-image: url(https://media.giphy.com/media/krQzJaxbU1aXS/giphy.gif);
      }
    }
  }
  .logo-box{
    padding:40px;
    background: $primary-light95;
    text-align:center;
    display: block;
    @extend .h-100;
    @extend .d-flex;
    @extend .align-items-center;
    img{
      @extend .mx-auto;
      @extend .img-fluid;
    }
  }
  .startup-sticky{
    @extend .mr-md-0;
    @extend .ml-md-0;
    margin-top:40px;
    background-color: black;
    .img-startup{
      img{
        object-fit: cover;
        max-width:none;
        object-position: center center;
        height: auto;
        width: 100%;
        height:100%;
      }
    }
    .ceo-startup{
      img{
        width: 100%;
      }
      color:$primary-light;
      &.color1{
        background:$primary-light50s;
        @include media-breakpoint-down(xs) {
          background:none;
          .text{
            background:$primary-light50s;
          }
        }
      }
      &.color2{
        background:$second-color;
        @include media-breakpoint-down(xs) {
          background:none;
          .text{
            background:$second-color;
          }
        }
      }
    }
  }
  .slider-companies{
    .startup-sticky{
      background: transparent;
    }
  }
}


#updates-zone{
  @include media-breakpoint-up(md) {
    padding-right:$padding-section-right;
    padding-left:$padding-section-left;
  }
  @include media-breakpoint-down(md) {
    padding-right:$padding-section-right-sm;
    padding-left:$padding-section-left-sm;
  }
  @extend .pt-5;
  
  .knockout-text{
    .clip-text{
      font-size:$font-size-Knockout;
      font-weight:$font-w-semibold;
      line-height: $font-size-Knockout;
      @include media-breakpoint-down(md) {
        font-size:$font-xxlg-2;
        line-height: $font-xxlg-2;
      }
      @include media-breakpoint-down(sm) {
        font-size:$font-xlg;
        line-height: $font-xlg;
      }
      &.clip-text_startup {
          background-image: url(https://media.giphy.com/media/krQzJaxbU1aXS/giphy.gif);
      }
    }
  }

  ul.updates-card-container {
    list-style: none;
    padding: 0px;
    
    li, .card-article {
      margin: 2.5rem 0;
      background: white;
      width: calc(50% - 30px);
      min-height: 420px;
      max-height: 550px;
      display: inline-block;
      overflow: hidden;

      &:first-child {
        height: auto;
        min-height: auto;
      }
      
      &:nth-child(even) {
        float: right;
        @include media-breakpoint-down(md) {
          float: none;
          width: 100%;
        }
      }
      
      &:nth-child(odd) {
        float: left;
        @include media-breakpoint-down(md) {
          float: none;
          width: 100%;
        }
      }
      
      &:last-child {
        width: 100%;
        min-height: 1px;
        background: none;
        clear: both;
          float: none;
      }
    }
    .card-article {
      @extend .d-flex;
      .container-card{
        @extend .w-100;
      }
      .figure{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .desc{
        @extend .p-4;
        .data{
          font-size: $font-default;
          @extend .mb-4;
          font-weight: $font-w-light;
        }
        h3{
          font-size: $font-lg;
          font-weight: $font-w-regular; 
        }
      }
      a{
        &:hover{
          text-decoration: none;
        }
      }
      &.right-image, &.left-image{
        .container-card{
          @extend .d-flex;
          .figure, .desc{
            @extend .col-md-6;
          }
          .figure{
            @extend .px-0;
          }
        }
      }
      &.right-image{
        .container-card{
          @extend .flex-row-reverse;
        }
      }
      &.red, &.gray, &.dark{
        color:$primary-light;
        a{
          color:$primary-light;
        }
      }
      &.red{
        background-color: $second-color;
        .btn-danger {
          background-color: $primary-light;
          border-color: $primary-light;
          color: $primary;
        }
      }
      &.gray{
        background-color: $primary-light50s;
      }
      &.dark{
        background-color: $primary;
      }
      &.cover{
        position: relative;
        .figure, .desc{
          position: absolute;
          top:0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
        }
        .desc{
          @extend .d-flex;
          @extend .flex-column;
          @extend .justify-content-end;
          color:$primary-light;
          background: rgb(0,0,0);
          background: linear-gradient(0deg, rgba(0,0,0,0.4682247899159664) 27%, rgba(0,0,0,0) 60%);
          a{
            color:$primary-light;
          }
        }
      }
    }
  }
}


#video-zone{
  padding-bottom: 150px;
  position: relative;
  z-index: 1;
  :root {
    --gutter: 15px;
    --outer-gutter: 60px;
    --col-4: calc(100% / 12 * 4 - var(--gutter) * 2);
    --col-8: calc(100% / 12 * 8 - var(--gutter) * 2);
  }
  
  body {
    margin: 0;
    
    font-family: 'Helvetica', 'Arial';
  }
  
  .container-video {
    width: 100%;
    position: relative;
  
    margin: var(--outer-gutter) 0;
    padding: 0 var(--outer-gutter);
    
    box-sizing: border-box;
  }
  
  .col {
    display: inline-block;
    
    position: relative;
    float: left;
    
    margin: 0;
    
    height: 400px;
    
    will-change: transform;
  }
  
  .other-content {
    width: 100%;
    height: 110px;
  }
  
  .video {
    width: 100%;
    
    transform-origin: 0% 0%;
    
    cursor: pointer;
    overflow: hidden;
    
    &::before {
      content: '\25BA';
      
      display: none;
      
      position: absolute;
      top: 50%;
      left: 50%;
      
      font-size: 30px;
      color: #fff;
      
      transform: translate(-50%, -50%);
      
      z-index: 1001;
    }
    
    &.expanded::before {
      content: '\2590\A0\258C';
    }
    
    &:hover::before {
      display: block;
    }
  }
  
  .video video {
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;
    
    object-fit: cover;
    
    pointer-events: none;
  }
}

#about-zone{
  @include media-breakpoint-up(md) {
    padding-right:$padding-section-right;
  }
  @include media-breakpoint-down(md) {
    padding-right:$padding-section-right-sm;
    padding-left:$padding-section-left-sm;
  }
  @include media-breakpoint-down(md) {
    padding-right:0px;
    padding-left:0px;
  }
  .col-about{
    padding-top: 100px;
    padding-bottom: 100px;
    @include media-breakpoint-down(md) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &.title-about{
      padding-left: 65px;
      padding-right: 45px;
      @include media-breakpoint-down(md) {
        padding-left:15px;
        padding-right:15px;
      }
      background-color: $second-color;
      color: $primary-light;
      h2{
        font-size: $font-lg-3;
        font-weight: $font-w-bold;
        @include media-breakpoint-down(lg) {
          font-size: $font-lg-21;
        }
        span{
          color: $primary;
        }
      }
    }
  }
}


#highlights-zone{
  @include section;
  @include py-7;
  a:hover{
    text-decoration: none;
  }
  figure{
    @extend .mb-3;
    img{
      width: 100%;
    }
  }
  time{
    font-size: $font-small;
    color: $second-color;
    @extend .mb-3;
  }
  h3{
    font-size: $font-medium;
    font-weight: $font-w-medium;
    @extend .mb-5;
  }
}

#portfolio-startups-zone{
  @include section;
  @include py-7;
}


