.site{
  position: relative;
  #wrapper-navbar{
    position: absolute;
    z-index: 2;
    @extend .w-100;
    background-color: transparent;
    transition: 1s;
    &:hover{
        background: rgba(0, 0, 0, 0.7);
        transition: 0.3s;
    }
    @include media-breakpoint-down(sm) {
      position: relative;
      background: $primary; 
    }  
  }
}
.navbar-dark{
    background-color: transparent;
    .logo{
        svg{
            width: 200px;
            transition: width .5s;
            .text-svg{
                fill: $primary-light;
            }
            @include media-breakpoint-down(lg) {
              width: 170px;
            }
            @include media-breakpoint-down(sm) {
              width: 150px;
            }
        }
    }
    &.navbar{
      @extend .px-0;
      @include media-breakpoint-up(sm) {
        padding-bottom: 0px;
      }
      .navbar-row{
        @include media-breakpoint-down(md) {
          margin-left: 0px !important;
        }
      }
      .container-fluid{
        &.container-top{
          @include media-breakpoint-up(md) {
          padding-right:$padding-section-right;
          padding-left:$padding-section-left;
          }
          @include media-breakpoint-down(md) {
          padding-right:$padding-section-right-sm;
          padding-left:$padding-section-left-sm;
          }
        }
      }
      .mega-menu-wrap{
        -webkit-flex: 1;
        flex: 1;
        .max-mega-menu{
          @extend .d-flex;
          @extend .justify-content-center;
          @include media-breakpoint-down(sm) {
            -webkit-box-orient: vertical!important;
            -webkit-box-direction: normal!important;
            -ms-flex-direction: column!important;
            flex-direction: column!important;
          }
          .mega-sub-menu{
            h3{
              font-size: $font-lg-4;
              font-weight: $font-w-bold;
              color: $primary;
              span{
                color: $second-color; 
              }
            }
          }
        }
      }
      .btn{
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        @include media-breakpoint-down(sm) {
          padding-left: .875rem;
          padding-right: .875rem;
        }
      }
      .button-content{
        .btn{
          padding: 0.5rem 1.5rem;
        }
      }
    }
    color: $primary-light;
    .navbar-toggler{
      border-color: rgba(255,255,255,.5);
    }
    .navbar-nav{
      .nav-link, .nav-link:hover{
        color: rgba(255,255,255,1);
        @extend .px-3;
      }
      .nav-item{
        border-bottom: 3px solid $primary;
        &:hover, &.current-menu-item{
          border-bottom: 3px solid $second-color;
        }
      }
      .dropdown-menu{
        background-color: $primary;
        .dropdown-item{
          color: $primary-light;
          &:focus, &:hover{
            background-color: $primary;
          }
        }
  
      }
    }
    .custom-sub-item{
        .mega-menu-row{
            > .mega-sub-menu{
                @extend .d-flex;
                @extend .justify-content-center;
            }
        }
    }
    .user-login{
        @extend .px-3;
        svg{
            width: 16px;
        }
    }
    .fixed-top {
      background: rgba(0, 0, 0, 0.8);
      .logo svg {
        width: 135px;
        height: 38px;
        transition: width .5s;
        @include media-breakpoint-down(lg) {
          width: 150px;
          height: 74px;
        }
      }
    }
}
  /*.right-header{
    position: relative;
    @extend .w-100;
    .gioin-url-ref{
      position: absolute;
      right: 90px;
      z-index: 3;
    }
    .search-box{
      position: relative;
      right: 0px;
      @extend .w-100;
    }
  }*/

.search-form{
    margin-top: 10px;
    &.w-100{
      transition: width 1s;
      backface-visibility:hidden;
      position: absolute;
    }
    .form-group{
      margin-bottom: 0px;
      position:relative;
      width:0%;
      min-width:50px;
      height:53px;
      overflow:hidden;
      transition: width 1s;
      backface-visibility:hidden;
      input{
        &.form-control::-webkit-input-placeholder{
          display:none;
          color: $primary-light;
        }
      }
      input{
        &.form-control::-moz-placeholder{
          display:none;
          color: $primary-light;
        }
      }
      input{
        &.form-control{
          position:absolute;
          top:0;
          right:0;
          outline:none;
          width:100%;
          height:43px;
          margin:0;
          z-index:10;
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $primary;
            opacity: 0; /* Firefox */
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $primary;
            opacity: 0;
          }
          
          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $primary;
            opacity: 0;
          }
        }
        input[type="text"].form-control{
          -webkit-appearence:none;
          -webkit-border-radius:0;
        }
      }
      &.sb-search-open{
        .search-label{
          .fa{
            color: $primary;
          }
        }
        input{
          &.form-control{
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              opacity: 1; /* Firefox */
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              opacity: 1;
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
              opacity: 1;
            }
          }
        }
      }
    }
    .form-control{
        background: none;
        border: none;
      &:focus{
        background: $primary;
        border-color: $primary-light;
      }
    }
}
  
  
  .form-control-submit,
  .search-label, #searchsubmit{
    width:40px;
    height:40px;
    position:absolute;
    right:0;
    top:0;
    padding:0;
    margin:0;
    text-align:center;
    cursor:pointer;
    line-height:40px;
    background:none;
  }
  .form-control-submit{
    background:none; /*stupid IE*/
    opacity: 0;
    color:transparent;
    border:none;
    outline:none;
    z-index:-1;
  }
  #searchsubmit{
    z-index:90;
  }
  .search-label{
    z-index:80;
    .fa{
      color: $primary-light;
    }
  }
  .form-group.sb-search-open,
  .no-js .sb-search-open{
    width:100%;
    .form-control{
      border-color: $primary-light;
    }
    input{
        border-color: $primary-light;
        background-color: $primary-light;
        &:focus{
            border-color: $primary-light;
            background-color: $primary-light;
        }
    }
  }
  .form-group.sb-search-open .search-label,
  .no-js .sb-search .search-label {
      background: $primary-light;
      color: $primary;
      z-index: 11;
  }
  .form-group.sb-search-open .form-control-submit,
  .no-js .form-control .form-control-submit{
      z-index: 90;
  }

  #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link{
    @include media-breakpoint-down(lg) {
        padding: 0px 10px 0px 10px !important;
    }
  }
  #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu{
    @include section;
  }
  #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item{
    .btn{
      padding: 0.5rem 1.5rem;
    }
  }

  #mega-menu-wrap-primary #mega-menu-primary li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column{
    border-left: 1px solid $primary-light81;
    padding-left: 15px;
  }
  #mega-menu-wrap-primary #mega-menu-primary li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column:first-child {
    border-left: 0px;
    padding-left: 0px; 
  }

  .sidenav {
    height: 100vh;
    width: 0;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.97);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
    @media screen and (max-height: 450px) {
      padding-top: 15px;
      a {
        font-size: 18px;
      }
    }
    .logo{
        svg{
            width: 150px;
            .text-svg{
                fill: $primary-light;
            }
        }
        a{
            padding-left: 5px;
        }
    }
    a {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      font-size: 25px;
      display: block;
      transition: 0.3s;
      &:hover {
        color: rgba(255,255,255,.7);
      }
      &.btn{
        font-size: inherit;
        &.btn-link{
            color: $primary-light;
            text-decoration: none ;
        }
      }
    }
    .closebtn {
      /*position: absolute;
      top: 0;
      right: 25px;*/
      font-size: 36px;
      //margin-right: 10px;
      color: $primary-light;
    }
    .form-control-submit,
    .search-label, #searchsubmit{
      //left:0;
    }
    .search-form .form-group input.form-control{
      //padding-left: 70px;
    }
    input[type="submit"]{
      border-color: $primary;
    }
    .form-control{
      &:focus{
        border-color: $primary !important;
      }
    }
    .social-row{
        #top, .scroll{
            display: none;
        }
        a, .fa-facebook, .fa-twitter, .fa-linkedin-square{
            color: $primary-light !important;
        }
        .fa-instagram{
            background: radial-gradient(circle at 30% 107%,#fff 0,#fff 5%,#fff 45%,#fff 60%,#fff 90%);
            -webkit-background-clip: text;
            /* Also define standard property for compatibility */
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .menu-mobile, .container-top{
        @include media-breakpoint-up(md) {
            padding-right:$padding-section-right;
            padding-left:$padding-section-left;
            }
            @include media-breakpoint-down(md) {
            padding-right:$padding-section-right-sm;
            padding-left:$padding-section-left-sm;
            }
    }
    .area-riservata-container, .lang-container{
        color: $primary-light;
    }

    .social-row, .area-riservata-container, .lang-container, .search-container{
        @extend .py-3;
        border-top: 1px solid rgba(243, 243, 243, 0.2);
        @extend .mx-3;
    }
    /*.mega-btn{
        display: none !important;
    }*/
    .btn{
      padding-left: 1.875rem;
      padding-right: 1.875rem;
      @include media-breakpoint-down(sm) {
        padding-left: .875rem;
        padding-right: .875rem;
      }
    }
    .form-group{
      input{
        &.form-control{
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $primary-light;
            opacity: 1; /* Firefox */
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $primary-light;
          }
          
          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $primary-light;
          }
        }
      }
    }
    .mega-menu-wrap{
      .max-mega-menu{
        .mega-sub-menu{
          h3{
            font-weight: $font-w-bold;
            color: $primary;
            span{
              color: $second-color; 
            }
          }
        }
      }
    }
    .button-content{
      a{
        display: inline-block;
      }
      .btn{
        padding: 0.45rem 1.5rem;
      }
    }
}

