body{
    //font-family: $primary-font !important;
    font-size: 16px;
    line-height: 1.7;
    &.page-template-page-how-we-work, &.page-template-page-portfolio-startup{
      overflow-x: hidden;
    }
}
.wrapper{
  padding: 0px 0px;
}

.fa-facebook{
    color: #3b5998;
}
.fa-twitter{
    color: #1DA1F2;
}
.fa-linkedin-square{
    color: #0e76a8;
}
.fa-instagram{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;
    /* Also define standard property for compatibility */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title-section{
  font-size: $font-lg-3;
  font-weight: $font-w-bold;
  @extend .pb-4;
  span{
    color: $second-color;
  }
}

.btn, .btn-lg{
    border-radius: 0rem;
    text-transform: uppercase;
    font-size: 0.9rem; 
    border-width: 2px;  
    font-weight: 500;
    @include media-breakpoint-up(sm) {
      padding: .8rem 3rem;
    }
    &.btn-sm{
      padding: .25rem .5rem;
    }
    &.btn-danger {
      background-color: $second-color;
      border-color: $second-color;
    }
    &.btn-outline-danger {
        color: $second-color;
        border-color: $second-color;
        &:hover, &:not(:disabled):not(.disabled):active{
          color: $primary-light;
          background-color: $second-color;
        }
    }
    &.btn-primary {
      background-color: $third-color;
      border-color: $third-color;
    }
    &.btn-outline-primary {
        color: $third-color;
        border-color: $third-color;
        &:hover, &:not(:disabled):not(.disabled):active{
          color: $primary-light;
          background-color: $third-color;
        }
    }
    &.btn-secondary {
      background-color: $fifth-color;
      border-color: $fifth-color;
    }
    &.btn-outline-secondary {
        color: $fifth-color;
        border-color: $fifth-color;
        &:hover, &:not(:disabled):not(.disabled):active{
          color: $primary-light;
          background-color: $third-color;
        }
    }
    &.btn-success {
      background-color: $forth-color;
      border-color: $forth-color;
    }
    &.btn-outline-success {
        color: $forth-color;
        border-color: $forth-color;
        &:hover, &:not(:disabled):not(.disabled):active{
          color: $forth-color;
          background-color: $forth-color;
        }
    }
    &.btn-light {
      background-color: $primary-light;
      border-color: $primary-light;
      color: $primary !important;
      &:hover, &:not(:disabled):not(.disabled):active{
        color: $primary !important;
      }
    }
    &.btn-outline-light {
        color: $primary-light;
        border-color: $primary-light;
        &:hover, &:not(:disabled):not(.disabled):active{
          color: $primary !important;
          background-color: $primary-light;
        }
    }
}
  .knockout-text{
    .clip-text{
      font-size:$font-xxlg;
      font-weight:$font-w-semibold;
      line-height: $font-xxlg;
      @include media-breakpoint-down(sm) {
        font-size:$font-xlg;
        line-height: $font-xlg;
      }
      &.clip-text_startup {
          background-image: url(https://media.giphy.com/media/krQzJaxbU1aXS/giphy.gif);
      }
    }
    &.knockout-xl{
      .clip-text{
        font-size:$font-size-Knockout;
        font-weight:$font-w-semibold;
        line-height: $font-size-Knockout;
        @media (max-width: 1360px){
          font-size:$font-xxlg-2;
          line-height: $font-xxlg-2;
        }
        @include media-breakpoint-down(sm) {
          font-size:$font-xlg;
          line-height: $font-xlg;
        }
        &.clip-text_innovation {
            background-image: url(https://media.giphy.com/media/U7yRCYz2b36RTPOS1R/giphy.gif);
            //background-image: url(https://media.giphy.com/media/3o7TKL943iNs6Uys5G/giphy.gif);
        }
      }
    }
  }
  .text-red, a .text-red, .text-red a{
    color: $second-color;
  }
  .text-und{
    text-decoration: underline;
  }
  .tit-72{
    font-size:$font-xxlg;
    @include media-breakpoint-down(md) {
      font-size:$font-xlg !important;
      line-height: $font-xlg;
    }
    @include media-breakpoint-down(sm) {
      font-size:$font-lg-3 !important;
    }
  }
  .highlight-event-section{
    @include section;
    @include dark-box;
    padding-top: 30px;
    padding-bottom: 30px;
    @extend .py-5;
    background-size: cover;
    @extend .text-center;
    @extend .text-md-left;
    .logo{
      svg{
        width: 100%;
        height: auto;
        @include media-breakpoint-down(md) {
          width: 50%;
          margin-bottom: 20px;
        }
      }
    }
    .title{
      h3{
        font-size: $font-lg;
      }
    }
    .cta{
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .justify-content-md-end;
      a{
        @extend .mx-2;
      }
      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
    }
    
  }

  .updates-section{
    @include section;
    @extend .pt-5;
    .knockout-text{
      .clip-text{
        font-size:$font-xxlg;
        font-weight:$font-w-semibold;
        line-height: $font-size-Knockout;
        @include media-breakpoint-down(md) {
          font-size:$font-xlg;
          line-height: $font-xxlg-2;
        }
        @include media-breakpoint-down(sm) {
          font-size:$font-xlg;
          line-height: $font-xlg;
        }
        &.clip-text_startup {
            background-image: url(https://media.giphy.com/media/krQzJaxbU1aXS/giphy.gif);
        }
      }
    }

    .card-article {
      .figure{
        width: 100%;
        height: 200px;
        img{
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .desc{
        @extend .p-4;
        .data{
          font-size: $font-default;
          @extend .mb-4;
          font-weight: $font-w-light;
          text-transform: uppercase;
        }
        h3{
          font-size: $font-medium;
          font-weight: $font-w-regular; 
        }
      }
      a{
        &:hover{
          text-decoration: none;
        }
      }
      &.red, &.gray, &.dark{
        color:$primary-light;
        a{
          color:$primary-light;
        }
      }
      &.red{
        background-color: $second-color;
        .btn-danger {
          background-color: $primary-light;
          border-color: $primary-light;
          color: $primary;
        }
      }
      &.gray{
        background-color: $primary-light50s;
      }
      &.dark{
        background-color: $primary;
      }
    }
  }
  #container-bradcrumbs{
    @extend .py-4;
    ul#breadcrumbs{
      list-style:none;
      @extend .d-flex;
      @extend .p-0;
      @extend .mb-0;
      white-space: nowrap;
      li{
        @extend .mr-2;
        color: $primary-light20;
        font-size: 0.75em;
        &.item-current{
          color: $second-color;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.separator, a{
          color: $primary-light60;
          &:hover{
            text-decoration: none;
          }
        }
        &.isDisabled, &.item-archive-team{
          a{
            pointer-events: none;
          }
        }
      }
    }
  }

// layout css components
  .bg-cover{
    min-height: 70vh;
    @extend .py-5;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include section;
    @include media-breakpoint-down(md) {
      background-color: rgba(0,0,0,.5);
      background-blend-mode: color;
      min-height: inherit;
      padding-top: 0px !important;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  
    &.cover{
      font-size: 1.125em;
      color: $primary-light;
      h1{
        font-size: $font-xlg;
        font-weight: 700;
        @include media-breakpoint-down(md) {
          font-size: 1.875em;
        }
      }
      .cover-content{
        border-left: 2px solid $primary-light;
        @include mt-8;
        @include media-breakpoint-down(sm) {
          margin-top: 0px !important;
        }
        .ancestor{
          font-weight: $font-w-bold;
          text-transform: uppercase;
          @extend .pb-2;
          span{
            color: $second-color;
          }
        }

        @include media-breakpoint-down(md) {
          background: rgb(255,255,255);
          background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.05) 59%, rgba(0,0,0,0.1) 86%, rgba(0,0,0,0.1) 86%);
          padding-top: 2em !important;
          padding-bottom: 2em !important;
          padding-left: 1em !important;
          padding-right: 1em !important; 
        }
      }
    }
  }
  .container-general-text, .fascia-testo{
    h4, h3{
      @extend .mb-4;
    }
    ul{
      li{
        @extend .mb-3;
      }
    }
    h1{
      font-size: $font-xxlg;
      line-height: 1;
      @include media-breakpoint-down(lg) {
        font-size: $font-lg-5;
      }
      @include media-breakpoint-down(sm) {
        font-size: $font-lg-3;
      }
    }
    h2{
      font-size: $font-lg-5;
      line-height: 1;
      @include media-breakpoint-down(lg) {
        font-size: $font-lg-3;
      }
      @include media-breakpoint-down(sm) {
        font-size: $font-lg;
      }
    }
    h3{
      font-size: $font-lg-3;
      line-height: 1;
      @include media-breakpoint-down(lg) {
        font-size: $font-lg;
      }
      @include media-breakpoint-down(sm) {
        font-size: $font-lg-2;
      }
    }
    h4{
      font-size: $font-lg;
      @include media-breakpoint-down(lg) {
        font-size: $font-medium;
      }
    }
  }
  .container-general-text{
    padding-top: 6rem;
    padding-bottom: 6rem;
    font-size: 1.125em;
    @include media-breakpoint-down(sm) {
      padding-top: 3rem;
      padding-bottom: 3rem;
      font-size: 1em;
    }
    h1, h2, h3{
      font-weight: 400;
      @extend .mb-4;
    }
    ul{
      @extend .pl-3;
      list-style: none; /* Remove default bullets */
      li{
        &::before {
          content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: $second-color; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
      }
    }
  }
  .fascia-testo{
    color: $primary-light;
    min-height: 35vh;
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-md-row;
    .container-fascia{
      @extend .w-100;
      @extend .d-flex;
      @extend .flex-column;
      @extend .flex-md-row;
      .text-container{
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-center;
        .text-content{
          @extend .px-2;
          @extend .px-lg-5;
          @extend .py-5;
        }
      }
      .cover{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include media-breakpoint-down(sm) {
          min-height: 40vh;
        }
      }
    }
    &.testo-video{
      display: block !important;
      .video-container {
        @include media-breakpoint-down(sm) {
          min-height: 40vh;
        }
      }
    }
    ul{
      @extend .pl-3;
    }
    a{
      &.link{
        @include link-button;
      }
    }
    .video-container{
      background-color: $primary;
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .px-0;
      overflow: hidden;
  
      .custom-video-player {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        iframe {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
        }
        video {
          position: absolute;
          top: 50%;
          left: 50%;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          z-index: 2;
          transform: translateX(-50%) translateY(-50%);
          background-size: cover;
          transition: 1s opacity;
        }
      }
    }
    .embed-vimeo{
      &>div{
        padding: 0px !important;
        position: initial !important;
      }
    }
  }

  .numeri-container{
    @extend .py-5;
    @include section;
    .header{
      font-size: 1.125rem;
      h3{
        font-size: 1.5em;
        font-weight: 700;
      }
    }
    .box-numeri{
      .row-numeri{
        @extend .justify-content-around;
        @extend .d-flex;
        .number{
          @extend .d-flex;
          @extend .mb-3;
          .container-number{
            @extend .flex-grow-1;
            @extend .p-4;
            font-size: 1.125em;
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-center;
            .number{
              @extend .d-flex;
              @extend .justify-content-center;
              @extend .align-items-end;
              @extend .mr-4;
              font-size: 3.75rem;
              font-weight: 300;
              line-height: .7;
              .side-text{
                font-size: 1.125rem;
                text-transform: uppercase;
                font-weight: 700;
                @extend .ml-3;
                line-height: 1;
              }
            }
            &.box-number{
              background: $primary-light;
              color: $primary-light60;
            }
          }
          &.col-md-3{
            .container-number{
              .number{
                .side-text{
                  font-size: 1rem;
                  @extend .ml-2;
                }
              }
            }
          }
        }
      }
    }
    &.container-dark, &.container-red, &.container-blue, &.container-green{
      color: $primary-light;
    }
    &.container-dark{
      background-color: $primary;
      .container-number{
        .number{
          color: $second-color;
        }
      }
    }
    &.container-red{
      background-color: $second-color;
      .container-number{
        &.box-number{
          .number{
            color: $second-color;
          }
        }
      }
    }
    &.container-gray{
      background-color: $primary-lightest;
      .container-number{
        &.box-number{
          .number{
            color: $second-color;
          }
        }
      }
    }
    &.container-blue{
      background-color: $third-color;
      .container-number{
        &.box-number{
          .number{
            color: $third-color;
          }
        }
      }
    }
    &.container-green{
      background-color: $forth-color;
      .container-number{
        &.box-number{
          .number{
            color: $forth-color;
          }
        }
      }
    }
  }
  .animated-title{
    @extend .py-5;
    @extend .text-center;
    .abstract{
      font-size: $font-lg;
      font-weight: $font-w-semibold;
    }
  }

.gallery-logo{
  @extend .py-5;
  .title-gallery{
    @extend .pb-5;
  }
  .loop-logo{
    margin: 0px 0px;
    padding: 0px 0px;
    list-style: none;;
    @extend .d-flex;
    @extend .flex-wrap;
    li{
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-center;
      @extend .align-items-center;
      @extend .my-3;
      .img-content{
        @extend .px-3;
      }
      img{
        max-width: 100%;
      }
    }
    &.gallery-tre{
      li{
        width: 33%;
      }
    }
    &.gallery-quattro{
      li{
        width: 25%;
        @include media-breakpoint-down(sm) {
          width: 33%;
        }
      }
    }
    &.gallery-cinque{
      li{
        width: 20%;
        @include media-breakpoint-down(sm) {
          width: 33%;
        }
      }
    }
  }
}

.layout-loghi-row{
  @extend .py-5;
  .content-testo-riga-loghi{
    @extend .pb-5;
  }
  .righe-loghi{
    @include section;
    .riga{
      @extend .row;
      @extend .justify-content-center;
      a:hover{
        text-decoration: none;
      }
      .logo{
        @extend .col-md-2;
        @extend .col-6;
        @extend .text-center;
        @extend .px-lg-5; 
        @extend .mb-3;
        font-weight: $font-w-semibold;
        .img-logo{
          @extend .mb-3;
          img{
            max-width: 100%;
          }
        }
      }
    }
  }
}

//layout page css components

#prize-zone{
  @include section;
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: $primary;
  .text1{
    font-size:$font-lg;
    font-weight: $font-w-bold;
    color: $primary-light;
    span{
      color: $second-color;
    }
  }
  .row-cols-5>*{
    /*@extend .col-6;
    @extend .my-1;
    @extend .px-1;
    @extend .d-flex;*/
    @include media-breakpoint-down(sm) {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .premio{
    background-color: $primary-light;
    flex: 1;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .p-3;
    width: 18vw;
    height: 11vw;
    @include media-breakpoint-down(lg) {
      height: 20vw;
    }
    @include media-breakpoint-down(sm) {
      height: 44vw;
    }
    img{
      max-width: 100%;
    }
    .text{
      font-size: $font-xsmall-1;
      line-height: 1;
    }
  }
}

.generic-bg-banner{ 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include section;
  @include py-6;
  @extend .d-flex;
  @extend .align-items-center;
  color: $primary-light;
  min-height: 540px;
  .text1{
    font-size: $font-lg-5;
    font-weight: $font-w-bold;
    @extend .pb-4;
    line-height: 1.4;
    span{
      color: $second-color;
    }
  }
}

.category-member, .category-program{
  .cat-header{
      @include mt-7;
      @extend .mb-4;
      h3{
        font-size: $font-lg-3;
        font-weight: $font-w-bold;
        span{
          color: $second-color;
        }
      }
  }
  .member{
    @extend .my-3;
    a{
      .content-member{
        position: relative;
        img{
          filter: url(filters.svg#grayscale);
          /* Firefox 3.5+ */
          filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          transition: all 0.2s;
          -webkit-transition: all 0.2s;
          -moz-transition: all 0.2s;
        }
        .title-member{
          position: absolute;
          z-index: 1;
          left: 0px;
          right: 0px;
          bottom: 0px;
          .ico-bio{
            text-align: center;
            display: none;
          }
          .title{
            @extend .px-3;
            @extend .py-1;
            @extend .py-lg-2;
            @extend .mt-3;
            background-color: rgba(198, 26, 39, 0.9);
            color: $primary-light;
            .name{
              font-size: $font-medium;
              font-weight: $font-w-semibold;
            }
            .role-bio{
              display: none;
            }
          }
        }
      }
      &:hover{
        .content-member{
          img{
            filter: grayscale(0);
            -webkit-filter: grayscale(0);
            -moz-filter: grayscale(0);
            filter: brightness(63%);
            -webkit-filter: brightness(63%);
            -moz-filter: brightness(63%);
          }
          .title-member{
            .ico-bio{
              display: block;
            }
            .title{
              .role-bio{
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
.category-program{
  .card-deck{
    @include media-breakpoint-down(md) {
      flex-flow: column wrap;
    }
  }
  .card {
    @extend .mb-3;
    background-color: $primary;
    color: $primary-light;
    border-radius: 0px;
    border: 0px;

    .card-img,
    .card-img-top {
      height: 200px;
      width: auto;
      -o-object-fit: cover;
      object-fit: cover;

    }

    .message {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      @extend .px-3;
      @extend .py-1;
      background-color: rgba(198, 26, 39, 0.9);
      font-weight: $font-w-semibold;
    }

    .card-body {
      .ico {
        @extend .py-3;
      }

      .card-title {
        font-size: $font-lg;
        font-weight: $font-w-medium;
        @extend .pb-3;
      }

      @extend .px-3;
      @extend .py-4;

      .col-ico {
        @extend .col-12;
        height: 110px;
        @extend .d-flex;
        @extend .align-items-center;
      }

      .col-desc {
        @extend .col-12;
      }

      .button-one-item {
        display: none;
      }
    }

    .card-footer {
      .button-row {
        .btn {
          &.btn-link {
            color: $second-color;
            @extend .p-0;

            svg {
              @extend .ml-1;
              margin-top: -5px;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .card-deck-3{
    .card{
      -ms-flex: 0 0 30.7%;
      flex: 0 0 30.7%;
      max-width: 30.7%;
      @include media-breakpoint-down(md) {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .program{
    @extend .d-flex;
    .content-program{
      background-color: $primary;
      color: $primary-light;
      .cover-program{
        height: 200px;
        overflow-y: hidden;
        position: relative;
          .message{
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            @extend .px-3;
            @extend .py-1;
            background-color: rgba(198, 26, 39, 0.9);
            font-weight: $font-w-semibold;
          }
        img{
          object-fit: cover;
          height: 100%;
        }
      }
      .title-program{
        .ico{
          @extend .py-3;
        }
        .title{
          font-size: $font-lg;
          font-weight: $font-w-medium;
          @extend .pb-3;
        }
        @extend .px-3;
        @extend .py-4;
        .col-ico{
          @extend .col-12;
          min-height: 110px;
          @extend .d-flex;
          @extend .align-items-center;
        }
        .col-desc{
          @extend .col-12;
        }
        .button-row{
          .btn{
            &.btn-link{
              color: $second-color;
              @extend .p-0;
              svg{
                @extend .ml-1;
                margin-top: -5px;
              }
              &:hover{
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
  &.one-program{
    .card-deck{
      .card{
        .card-body{
          @extend .row;
          .col-ico{
            @extend .col-lg-4;
            @extend .d-flex;
            @extend .justify-content-lg-center;
            @extend .justify-content-start;
            @extend .align-items-lg-center;
            @extend .align-items-start;
          }
          .text{
            @extend .col-lg-8;
          }
          .button-one-item{
            display: block;
          }
        }
        .card-footer{
          display: none;
        }
      }
    }
    .program{
      .content-program{
        .cover-program{
          height: auto;
          img{
            object-fit: initial;
            height: auto;
          }
        }
        .title-program{
          .ico{
            @extend .d-flex;
            @extend .justify-content-lg-center;
            @extend .justify-content-start;
            @extend .align-items-lg-center;
            @extend .align-items-start;
          }
          .col-ico{
            @extend .col-lg-4;
          }
          .col-desc{
            @extend .col-lg-8;
          }
        }
      }
    }
    /*.card-deck{
      .card{
        .card-footer{
          display: none;
        }
      }
    }*/
  }
}

.quote-container{
  .quote{
    &.quote-close{
      text-align: right;
    }
    @include media-breakpoint-down(sm) {
      svg{
        width: 30px;
        height: auto;
      }
    }
  }
  .content-quote{
    padding-left: 64px;
    padding-right: 64px;
    font-size: $font-lg;
    text-align: center;
    p:last-child{
      margin-bottom: 0px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 30px;
      padding-right: 30px;
      font-size: $font-medium;
    }
  }
}

.team-index-container{
  h3{
    font-size: $font-lg-3;
    font-weight: $font-w-bold;
    span{
      color: $second-color;
    }
  }
  .nav-tabs{
    .nav-link{
      border-color: #fff #fff #dee2e6; 
      text-transform: uppercase;
      font-weight: $font-w-semibold;
      font-size: $font-small;
      color: $primary-light40;
      &.active{
        border-color: #fff #fff $second-color;
        color: $second-color;
      }
      &:hover{
        color: $second-color;
      }
    }
  }
  .category-member{
    .tab-content{
      .tab-pane{
        .member{
          @extend .d-flex;
          .content-member{
            .title-member{
              position: relative;
              .title{
                @extend .py-0;
                @extend .mt-0;
              }
            }
          }
          a{
            background-color: $second-color;
            &:hover{
              text-decoration: none ;
              background-color: $primary;
              .title{
                background-color: $primary;
              }
              .content-member{
                img{
                  filter: brightness(100%);
                  -webkit-filter: brightness(100%);
                  -moz-filter: brightness(100%); 
                }
              }
            }
          }
        }
      }
    }
  }
}

.select-tab-container{
  .select-tab-navigation{
    @extend .pb-3;
    @extend .d-flex;
    @extend .justify-content-end;
    @extend .align-items-center;
    font-size: $font-small;
    font-weight: $font-w-bold;
    text-transform: uppercase;
    span{
      @extend .mr-3;
      color: $primary-light20;
    }
    select, select:focus-visible{
      border-width: 0px;
      color: $second-color;
      font-weight: $font-w-bold;
    }
  }
  .select-tab-content{
    .sezione-contenuto{
      @extend .pb-4;
      h3{
        color: $second-color;
        font-size: $font-lg;
        font-weight: $font-w-semibold;
        @extend .pb-3;
      }
      .content{
        @extend .pb-1;
        @extend .mb-2;
        border-bottom: 1px solid $primary-light81;
        a:hover{
          text-decoration: none ;
        }
        .fa{
          color: $second-color;
          margin-top: 10px;
        }
        .content-col{
          color: $primary-light20;
          time{
            font-size: $font-small-1;
          }
          h4{
            font-size: $font-medium;
            font-weight: $font-w-semibold;
          }
        }
        &:last-child{
          border-bottom: 0px;
        }
      }
    }
  }
}

.events-list{
  .title{
    color: $second-color;
    font-size: $font-lg;
    font-weight: $font-w-semibold;
    @extend .mb-2;
    @include media-breakpoint-down(lg) {
      font-size: $font-lg-2;
    }
  }
  .event{
    @extend .mb-3;
    @extend .p-3;
    background-color: $primary-light95;
    font-size: $font-lg-2;
    font-weight: $font-w-semibold;
    @include media-breakpoint-down(lg) {
      font-size: $font-default;
    }
    .data{
      @extend .mb-2;
      span{
        background-color: $second-color;
        display: inline-block;
        @extend .px-2;
        color: $primary-light;
      }
    }
    .ora, .sottotitolo-event{
      font-size: $font-small;
      font-weight: $font-w-regular;
    }
    h3{
      font-size: $font-lg-2;
      font-weight: $font-w-semibold;
    }
    .row-event{
      @extend .pb-3;
      &:last-child{
        @extend .pb-0;
      }
    }
  }
}


#timeline-section{
  @extend .pt-5;
  @include pb-8;
  @include section;
  .row-timeline{
      @extend .align-items-end;
      .timeline-box{
          border-top: 3px solid $second-color;
          //margin-left: 20px;
          @extend .mb-3;
          //white-space: pre;
          font-size: $font-lg-2;
          color: $primary;
          .date{
              color: $second-color;
              @extend .mb-0;
              @extend .mb-lg-4;
          }
          .title{
              font-weight: $font-w-bold;
              @extend .mb-5;
              @extend .mb-lg-0;
              text-transform: uppercase;
              span{
                color: $second-color;
              }
          }
          &:before{
              content: "";
              //background-image: url(/img/circle.svg);
              width: 15px;
              height: 15px;
              background-position: center;
              background-repeat: no-repeat;
              display: block;
              margin-top: -9px;
              margin-left: -27px;
          }
          img{
            max-width: 50px;
            height: auto;
            margin-top: -60px;
          }
      }
      .timeline-big-box{
          border: 1px solid $second-color;
          margin-bottom: -1px;
      }
      .title-timeline {
          font-size: $font-lg;
          font-weight: $font-w-bold;
          color: $second-color;
          text-align: center;
          @extend .py-3;
          margin-top: -40px;
          margin-bottom: 30px;
          .text-title{
            display: inline-block;
            @extend .py-1;
            @extend .px-2;
            background-color: $primary-light;
          }
      }
      .box-container-big-box, .box-container{
        @extend .px-lg-0;
        @extend .px-3;
        .box-container:first-child{
          @extend .pl-3;
        }
        .box-container:last-child{
          @extend .pr-3;
        }
        .box-container{
          .timeline-box{
            @extend .px-3;
          }
        }
      }
  }
}


#processi{
  background-image: url(../img/process-bg.jpeg);
  background-position: center;
  background-size: cover;
  @include section;
  @include py-8;
  margin-left: -15px;
  margin-right: -15px;
  @include media-breakpoint-down(md) {
    margin-left: -32px;
    margin-right: -32px;
  }
  .title-section{
    color: $primary-light;
    span{
      color: $second-color;
    }
  }
  .row-cols-5>*{
    /*@extend .col-6;
    @extend .my-1;
    @extend .px-1;
    @extend .d-flex;*/
    @include media-breakpoint-down(md) {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-process{
    @extend .my-4;
    @extend .d-flex;
    .box-process{
      flex: 1;
      @extend .p-3;
      background-color: $primary-light95;
      color: $primary-light20;
      .number{
        width: 45px;
        height: 45px;
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .align-items-center;
        background-color: $second-color;
        color: $primary-light;
        font-size: $font-lg;
        font-weight: $font-w-medium;
        border-radius: 8rem;
        margin-top: -35px;
      }
      figure{
        height: 40px;
        @extend .my-3;
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .align-items-center;
        img{
          max-height: 100%;
          width: auto;
        }
      }
    }
  }
}

#grow-section{
  @include py-8;
  .circle-symbol{
      background-color: $second-color;
      width: 99px;
      height: 99px;
      border-radius: 4rem;
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .align-items-center;
  }

  .row-cols-5>*{
    /*@extend .col-6;
    @extend .my-1;
    @extend .px-1;
    @extend .d-flex;*/
    @include media-breakpoint-down(md) {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .col-box{
    @extend .d-flex;
    @extend .flex-column;
    .box{
        flex: 1;
        @extend .mb-5;
        @extend .px-3;
        margin-top: 65px;
        border: 1px solid rgba(224, 234, 237, 1);
        background-color: $primary-light;
        .symbol-box{
            @extend .d-flex;
            @extend .justify-content-center;
            margin-top: -65px;
            @extend .mb-2;
        }
        ul{
          list-style: none;
          @extend .p-0;
          li{
            font-weight: $font-w-semibold;
            font-size: $font-default;
            @extend .py-2;
            border-bottom: 1px solid rgba(224, 234, 237, 1);
            @extend .d-flex;
            //@extend .justify-content-between;
            @extend .align-items-center;
            &.title, &:last-child{
              border-bottom: 0px;
            }
            &.title{
              @extend .justify-content-center;
              font-size: $font-medium;
              font-weight: $font-w-bold;
              color: $second-color;
              text-align: center;
            }
            strong{
              font-size: $font-medium;
            }
          }
        }
    }
  }
  .box{
      ul{
        li{
          font-weight: $font-w-bold;
          border-bottom: 0px solid rgba(224, 234, 237, 1);
          font-size: $font-default;
            strong{
                //font-size: $font-s-large2;
                font-weight: $font-w-bold;
                color: $second-color;
            }
        }
      }
  }
}

.row-startup{
  a{
    &:hover{
      text-decoration: none;
    }
  }
  .startup-card{
    .figure{
      //min-height: 18vh;
      min-height: 15vh;
      @extend .py-5;
      @extend .px-3;
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .align-items-center;
      position: relative;
      //background-color: $primary-light90;
      .exit-badge{
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        @extend .px-3;
        @extend .py-1;
        background: $second-color;
        color: $primary-light;
        font-weight: 700;
        display: none;
      }
      img, img.wp-post-image {
        max-width: 100%;
        width: auto;
        max-height: 80px;
        height: auto;
      }
    }
    .startup{
      @extend .mb-5;
      font-size: .75em;
      color: $primary-light60;
        &:hover{
          text-decoration: none;
        }
        header{
          @extend .px-3;
          //@extend .mt-3;
          &.text-left{
            text-align: center !important;
          }
          h3{
            font-size: 1.125em;
            font-weight: 400;
            color: $primary;
          }
        }
    }
    &.exit-startup{
      .figure{
        .exit-badge{
          display: block;
        }
      }
      .exit-badge {
        width: 100px;
        height: 20px;
        background: $forth-color;
        text-align: center;
        color: $primary-light;
        font-weight: $font-w-bold;
        margin-top: .2rem;
        line-height: 1;
      }
      .exit-badge:before {
        content: "";
        position: absolute;
        right: -10px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid $forth-color;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
      header{
        h3{
          color: $forth-color;
        }
      }
    }
  }
  #root{
    @extend .w-100;
    .App{
      @extend .w-100;
    }
  }
  .loader{
    .fa{
      color: $second-color;
    }
  }
  .container-startup{
    @extend .container;
    .more-button{
      @extend .col-12;
      @extend .pt-5;
    }
  }
}

#bcorp-zone {
  background-image: url(../img/bcorp-banner-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include section;
  @include py-7;
  color: $primary-light;

  h3 {
    font-size: $font-lg-3;
    font-weight: $font-w-bold;
    margin-right: 40px;

    @include media-breakpoint-down(md) {
      margin-right: 0px;
    }

    span {
      color: $second-color;
    }
  }
  .b-corp-logo{
    svg{
      width: 104px;
      fill: $primary-light;
    }
  }
}